body {
  word-wrap: break-word;
}

.App {
  text-align: center;
  color: #333;
  padding: 20px;
}

a {
  color: #333;
}

.twitter-button {
  color: #fff;
  background: #55acee;
  font-size: 20px;
  border-radius: 4px;
  cursor: pointer;
  padding: 4px 14px;
}

.twitter-button svg {
  margin-right: 6px;
}

.content {
  margin-top: 40px;
}

.caption {
  max-width: 600px;
  margin: 0 auto;
  margin-top: 40px;
}

.description {
  max-width: 600px;
  background: #eee;
  border: 4px solid #333;
  border-radius: 2px;
  margin: 0 auto;
  margin-top: 30px;
  padding: 10px;
  padding-top: 0;
}

.description h3 {
  margin-top: 30px;
  padding-bottom: 10px;
}

.description ul {
  text-align: left;
  padding-left: 1em;
  margin-bottom: 0;
  margin-top: 0;
}

.copy {
  margin-top: 30px;
  margin-bottom: 20px;
}

.spin {
  animation: loading 3s linear infinite;
}

@keyframes loading {
  0% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}
